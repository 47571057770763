import '@remento-infrastructure/integrations/@types.wisepops.js';

import logger from '@remento-infrastructure/common/logger.js';

import { Debugger } from '../../debugger.js';
import { Payload, Router } from '../../router.js';

import { EmailFormSubmitEvent, EmailFormSubmitPayload } from './form.types.js';

function extractFormPayload($formTarget: HTMLFormElement): Payload<EmailFormSubmitPayload> | null {
  const $emailInput = $formTarget.querySelector('input[type="email"]') as HTMLInputElement;
  if ($emailInput == null) {
    logger.warn(`[OBSERVER - FORM - WISEPOPS] Failed to extract payload`, $formTarget);
    return null;
  }
  const $formType = $formTarget.querySelector('[name="formType"]') as HTMLInputElement;
  const $formListId = $formTarget.querySelector('[name="formListId"]') as HTMLInputElement;

  return {
    data: {
      email: $emailInput.value,
      formType: $formTarget.dataset.analyticsType ?? $formType?.value ?? 'unknown',
      formListId: $formTarget.dataset.analyticsListId ?? $formListId?.value ?? undefined,
    },
    properties: {},
  };
}

export function registerWisepopsEmailFormsObserver(router: Router, debug?: Debugger) {
  if (!router.registerPublisher('email-form-wisepops-observer')) {
    console.warn('[OBSERVER - FORM - WISEPOPS] The hook is already active');
    return;
  }
  window.wisepops('listen', 'after-form-submit', function (event) {
    const $formTarget = event.target as HTMLFormElement;
    const payload = extractFormPayload($formTarget);
    if (payload == null) {
      return;
    }
    logger.debug(`[OBSERVER - FORM - UNBOUNCE] Publishing event ${EmailFormSubmitEvent}`, payload);
    router.publish(EmailFormSubmitEvent, {
      ...payload,
      properties: { wpDialogId: event.detail.popup.id, wpCampaignId: event.detail.popup.campaignId },
    });
  });

  if (debug != null) {
    let debugMutationObserver: MutationObserver | null = null;
    // The debug tool tips have to be rendered dynamically since wisepops does not necessarily display the form upfron
    window.wisepops('listen', 'before-popup-display', function (event) {
      const renderTooltip = () => {
        const element = event.target.querySelector('form button');
        if (element == null) {
          console.log('Not found');
          return;
        }
        debug?.initTooltip(element as HTMLElement, ($element) => {
          const $form = $element.closest('form');
          const payload = $form == null ? null : extractFormPayload($form);
          return { type: 'website.form', payload, identify: undefined };
        });
      };
      const $dialog = event.detail.target;
      debugMutationObserver = new MutationObserver(renderTooltip);
      debugMutationObserver.observe($dialog, { subtree: true, childList: true });
      $dialog.style.zIndex = '1000';
      $dialog.parentElement!.style.zIndex = '1000';
      renderTooltip();
    });
    window.wisepops('listen', 'after-popup-close', function (event) {
      if (debugMutationObserver != null) {
        debugMutationObserver.disconnect();
      }
    });
  }
}
