import '@remento-infrastructure/analytics-client/browser.js';
import '@remento-infrastructure/analytics-integrations/@types.js';

import { RumInitConfiguration } from '@datadog/browser-rum';
import { AnalyticsConfig } from '@remento-infrastructure/analytics-integrations/@types.js';
import { getRuntimeConfig, initializeAnalytics } from '@remento-infrastructure/analytics-integrations/init.js';
import { registerActivityHandler } from '@remento-infrastructure/analytics-integrations/sources/activity/activity-handler.js';
import { registerActivityPlugin } from '@remento-infrastructure/analytics-integrations/sources/activity/activity-plugin.js';
import { registerClickHandler } from '@remento-infrastructure/analytics-integrations/sources/click/click-handler.js';
import { registerClickObserver } from '@remento-infrastructure/analytics-integrations/sources/click/click-observer.js';
import { registerEmailFormHandler } from '@remento-infrastructure/analytics-integrations/sources/form/form-handler.js';
import { registerWisepopsEmailFormsObserver } from '@remento-infrastructure/analytics-integrations/sources/form/form-observer-wisepops.js';
import { registerActivityTransform } from '@remento-infrastructure/analytics-integrations/transforms/activity.js';
import { registerCookiesTransform } from '@remento-infrastructure/analytics-integrations/transforms/cookies.js';
import { registerPageTransform } from '@remento-infrastructure/analytics-integrations/transforms/page.js';
import { waitForWisepopsLoaded } from '@remento-infrastructure/integrations/wisepops.js';

if (window.ANALYTICS_PAGE) {
  const ANALYTICS_CONFIG: AnalyticsConfig = {
    clientSegmentKey: 'Cl8wAh0T4aBwBM3o8GOFwdWbN1E8AjwS',
    clientSource: 'remento-welcome-web',
    clientAPIHost: 'segment.remento.co',
    clientCDNUrl: 'https://segment-cdn.remento.co',
    debugConfig: getRuntimeConfig('debug', null) == null ? null : {},
    loggerLevel: getRuntimeConfig('logger-level', null),
  };

  const DATADOG_CONFIG: RumInitConfiguration = {
    applicationId: 'f527a955-0d4c-43a5-bde0-6ff54325100e',
    clientToken: 'pub737973c395fcbb1c3bc5852f1274f4bc',
    site: 'us5.datadoghq.com',
    service: 'remento-welcome-web',
    env: new URL(document.URL).hostname.endsWith('remento.co') ? 'production' : 'development',
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  };

  initializeAnalytics(ANALYTICS_CONFIG, DATADOG_CONFIG, (context) => {
    registerActivityPlugin(context, 20 * 1000, 10 * 1000);

    registerCookiesTransform(context.router);
    registerPageTransform(context.router, ANALYTICS_CONFIG.clientSource);
    registerActivityTransform(context.router);

    registerClickHandler(context);
    registerEmailFormHandler(context);
    registerActivityHandler(context);

    registerClickObserver(context.router, context.debugger);
    waitForWisepopsLoaded().then(() => registerWisepopsEmailFormsObserver(context.router, context.debugger));
  });
}
